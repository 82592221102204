import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {FilterField, GroupOption} from "../../../types/sherpa";
import _ from "lodash";

@Component({
	selector: 'base-filter-groupby',
	templateUrl: './filter-groupby.component.html',
	styleUrls: ['./filter-groupby.component.scss']
})
export class FilterGroupbyComponent implements OnInit {

	@Input() panelObservable: Observable<AnalysePanel>;
	@Input() options: GroupOption[];
	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();

	subscription: Subscription;
	selectedOptions: GroupOption[] = [];

	collapsed: boolean = true;

	constructor(private cd: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.subscription = this.panelObservable.subscribe(panel => {
			if (panel === null) {
				return;
			}

			this.setSelectedValues(panel.groupBy);
			this.cd.markForCheck();
		});
	}

	done() {
		const field = {key: 'groupBy', expectedValue: this.selectedOptions} as FilterField;

		// sent to parent
		this.onFilterFieldChanged.emit(field);
	}

	setSelectedValues(panelOptions: GroupOption[]) {
		this.selectedOptions = [];

		_.forEach(this.options, option => {
			if(_.find(panelOptions, ['key', option.key])) {
				this.selectedOptions.push(option);
			}
		});

		if(this.selectedOptions.length === 0) {
			const date = _.find(this.options, ['key', 'date_year_month']);
			if(date) {
				this.selectedOptions.push(date);
			}
		}
	}

	addGroupBy(option: GroupOption) {
		if(this.includes(option)) {
			this.removeGroupBy(option);
			return;
		}

		if(this.selectedOptions.length === 2) {
			this.selectedOptions[1] = option;
		}
		else {
			this.selectedOptions.push(option);
		}

		this.done();
	}

	removeGroupBy(option: GroupOption) {
		if(this.selectedOptions.length === 1) {
			//console.log('minimal 1');
			return;
		}

		_.remove(this.selectedOptions, option);
		this.done();
	}

	includes(option: GroupOption) {
		return _.some(this.selectedOptions, ['key', option.key]);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
