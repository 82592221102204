import {Injectable} from '@angular/core';
import {
	HttpResponse,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {util} from "../../../services";
import _ from "lodash";
import {global} from "../../../globals";
import {AppLoader} from "../../classes/Globals";

@Injectable({
	providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {

	private requests: HttpRequest<any>[] = [];

	constructor() {
	}

	removeRequest(req: HttpRequest<any>) {
		// remove request
		this.requests = _.without(this.requests, req);

		// check if loading
		util.isLoading.next(this.requests.length > 0);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// meaning sherpa-call
		if(req.method === 'POST') {
			this.requests.push(req);
			//console.log("Total requests:", this.requests.length);

			// new request, so loading
			if(global.loader === AppLoader.PAGE) {
				util.isLoading.next(true);
			}
		}

		return new Observable(subscriber => {
			const subscription = next.handle(req)
				.subscribe(
					event => {
						if (event instanceof HttpResponse) {
							this.removeRequest(req);
							subscriber.next(event);
						}
					},
					err => {
						this.removeRequest(req);
						subscriber.error(err);
					},
					() => {
						this.removeRequest(req);
						subscriber.complete();
					});

			// remove request from queue when cancelled
			return () => {
				this.removeRequest(req);
				subscription.unsubscribe();
			};
		})
	}
}
