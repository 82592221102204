import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter, FilterField, QueryConfiguration} from "../../../types/sherpa";
import {Observable, Subscription} from "rxjs";
import _ from "lodash";
import {SelectItem, TreeNode} from "primeng/api";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";

@Component({
  selector: 'base-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss']
})
export class FilterDateComponent implements OnInit {

	@Input() filter: Filter;
	@Input() panelObservable: Observable<AnalysePanel>;
	subscription: Subscription;

	// callback to parent
	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();

	collapsed: boolean = true;
	selectedValue: Date[] = null;
	fieldLabel: string = null;
	searchQuery: string = '';
	options: SelectItem[];
	isTree: boolean = true;
	date1: Date = null;
	date2: Date = null;
	relevantFiltersOnly: boolean = false;

	treeNodes: TreeNode[] = [];
	selectedNodes: TreeNode[];

	constructor(private cd: ChangeDetectorRef) {
		this.options = [{label: 'Lijstweergave', value: true}, {label: 'Van / Tot', value: false}];
	}

	ngOnInit(): void {
		// create tree object
		this.initTree();

		// subscription when panel changed
		this.subscription = this.panelObservable.subscribe(panel => {
			if(panel === null) {
				return;
			}

			this.relevantFiltersOnly = panel.relevantFiltersOnly;
			this.setSelectedValues(panel.andFilterFields);
			this.cd.markForCheck();
		});
	}

	initTree() {
		let key: number = 1;
		let key2: number = 1;
		let nodes: TreeNode[] = [];

		_.forEach(this.filter.values, item => {
			let parent = {
				key: `${key}`,
				selectable: true,
				label: item.label,
				children: []
			} as TreeNode;

			_.forEach(item.value, subItem => {
				let child = {
					key: `${key}.${key2}`,
					selectable: true,
					label: subItem.label,
					data: subItem.value
				} as TreeNode;

				parent.children.push(child);
				key2++;
			});

			nodes.push(parent);
			key++;
			key2 = 1;
		});

		let root = {
			key: 'root',
			selectable: true,
			expanded: true,
			styleClass: 'locked',
			label: '--alles--',
			children: nodes
		} as TreeNode;

		this.treeNodes = [root];
	}

	done() {
		const childrenOnly = _.filter(this.selectedNodes, node => {
			return !_.isNil(node.data);
		});

		let valuesOnly = _.map(childrenOnly, 'data');

		// override valuesOnly when not tree
		if(!this.isTree) {
			this.selectedNodes = [];
			this.treeNodes[0].partialSelected = false;

			_.forEach(this.treeNodes[0].children, rootNode => {
				rootNode.partialSelected = false;
			});

			valuesOnly = {
				minValue: this.date1 || null,
				maxValue: this.date2 || null
			} as any;
		}

		// trigger parent
		this.onFilterFieldChanged.emit({key: this.filter.key, expectedValue: valuesOnly});

		this.updateFieldLabel();
	}

	setSelectedValues(newFilterFieldConfig: FilterField[]) {
		let matchedField = _.find(newFilterFieldConfig, ['key', this.filter.key]) || {} as FilterField;
		let count: number = 0;
		let any: boolean = false;

		this.treeNodes[0].partialSelected = false;
		this.selectedNodes = [];

		if(_.has(matchedField.expectedValue, 'minValue') && _.has(matchedField.expectedValue, 'maxValue')) {

			this.date1 = new Date(matchedField.expectedValue.minValue);
			this.date2 = new Date(matchedField.expectedValue.maxValue);
			this.isTree = false;

			// update the displayed label
			this.updateFieldLabel();
			return;
		}

		_.forEach(this.treeNodes[0].children, rootNode => {
			count = 0;
			rootNode.partialSelected = false;

			_.forEach(rootNode.children, childNode => {
				if(_.includes(matchedField.expectedValue, childNode.data)) {
					rootNode.partialSelected = true;
					childNode.parent = rootNode;
					count++;
					any = true;
					this.selectedNodes.push(childNode);
				}
			});

			// when all children are selected, also push the rootNode in selectedNodes
			if(count === rootNode.children.length) {
				rootNode.partialSelected = false;
				this.selectedNodes.push(rootNode);
			}
		});

		this.treeNodes[0].partialSelected = any;

		// update the displayed label
		this.updateFieldLabel();
	}

	updateFieldLabel() {
		this.fieldLabel = null;

		if(!this.isTree) {
			if(this.date1 === null && this.date2 === null) {
				return;
			}

			if(this.date1 === null && this.date2 !== null) {
				this.fieldLabel = `(alle data tot ${this.date2.toLocaleDateString("nl-NL")})`;
			}
			else if(this.date1 !== null && this.date2 === null) {
				this.fieldLabel = `(${this.date1.toLocaleDateString("nl-NL")} - vandaag)`;
			}
			else {
				this.fieldLabel = `(${this.date1.toLocaleDateString("nl-NL")} - ${this.date2.toLocaleDateString("nl-NL")})`;
			}

			return;
		}

		const childrenOnly = _.filter(this.selectedNodes, node => {
			return !_.isNil(node.data);
		});

		if(childrenOnly.length) {
			this.fieldLabel = `(${childrenOnly.length} geselecteerd)`;
		}
	}

	getCaption() {
		this.fieldLabel = null;

		if(!this.isTree) {
			if(this.date1 === null && this.date2 === null) {
				return;
			}

			if(this.date1 === null && this.date2 !== null) {
				this.fieldLabel = `alle data tot ${this.date2.toLocaleDateString("nl-NL")}`;
			}
			else if(this.date1 !== null && this.date2 === null) {
				this.fieldLabel = `${this.date1.toLocaleDateString("nl-NL")} - vandaag`;
			}
			else {
				this.fieldLabel = `${this.date1.toLocaleDateString("nl-NL")} - ${this.date2.toLocaleDateString("nl-NL")}`;
			}

			return;
		}

		const childrenOnly = _.filter(this.selectedNodes, node => {
			return !_.isNil(node.data);
		});

		const parentsOnly = _.filter(this.selectedNodes, node => {
			return !_.isNil(node.children) && node.key !== 'root';
		});

		if(parentsOnly.length) {
			let countPartials: number = childrenOnly.length;
			let countPartialsLabel: string = '';

			_.forEach(parentsOnly, node => {
				countPartials -= node.children.length;
			});

			if(countPartials > 0) {
				let label = countPartials === 1 ? 'maand' : 'maanden';
				countPartialsLabel = ` en ${countPartials} ${label}`;
			}

			if(parentsOnly.length === 1) {
				this.fieldLabel = `${parentsOnly[0].label}${countPartialsLabel} geselecteerd`;
			}
			else {
				this.fieldLabel = `${parentsOnly.length} jaren${countPartialsLabel} geselecteerd`;
			}
		}
		else if(childrenOnly.length) {
			if(childrenOnly.length === 1) {
				this.fieldLabel = `${this.selectedNodes[0].label} - ${this.selectedNodes[0].parent.label} geselecteerd`;
			}
			else {
				const parents = _.map(childrenOnly, 'parent');
				const uniqueParents = _.uniqBy(parents, 'label');

				if(uniqueParents.length === 1) {
					this.fieldLabel = `${childrenOnly.length} maanden in ${uniqueParents[0].label} geselecteerd`;
				}
				else {
					this.fieldLabel = `${childrenOnly.length} maanden geselecteerd`;
				}
			}
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
