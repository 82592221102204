

<div *ngIf="showLink" class="p-field p-fluid p-pt-1">
	<label for="link" class="">Link</label>
	<div class="p-inputgroup">
		<input id="link" type="text" pInputText placeholder="Link" aria-describedby="link-help" [value]="url" readonly>
		<a pButton pRipple type="button"
		   class=""
		   icon="pi pi-download"
		   [style]="{'text-decoration': 'auto'}"
		   [label]="action"
		   [href]="url"></a>
	</div>
</div>

<div *ngIf="!showLink" class="p-field p-fluid p-pt-1">
	<div class="p-inputgroup">
		<a pButton pRipple type="button"
		   class="p-button-rounded"
		   icon="pi pi-download"
		   [style]="{'text-decoration': 'auto'}"
		   [label]="action"
		   [href]="url"></a>
	</div>
</div>


<small id="link-help">{{message}}</small>



