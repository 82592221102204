import {Injectable} from '@angular/core';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';
import Feature from "ol/Feature";
import _ from 'lodash';
import {StyleFunction, StyleLike} from "ol/style/Style";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPoint from "ol/geom/MultiPoint";
import {LiteralStyle} from "ol/style/LiteralStyle";

@Injectable({
	providedIn: 'root'
})
export class StyleService {


	static selectedConcession(feature: Feature, resolution: number): Style[] {
		let s = new Style({
			stroke: new Stroke({
				color: '#C7C7C7',
				width: 1.2
			}),
			zIndex: 10
		});

		return [s];
	}

	static colorStyle(feature: Feature, resolution: number): Style[] {

		let color = feature.get('color');
		let zIndex = feature.get('value');

		let coloredStyle = new Style({
			stroke: new Stroke({
				color: '#fff',
				width: 1.2
			}),
			fill: new Fill({
				color: color === '' ? '#FFF' : color,
			}),
			zIndex: zIndex === null ? 1 : 5,
		});

		return [coloredStyle];
	}

	static activeColorStyle(feature: Feature, resolution: number): Style[] {

		let origin = StyleService.colorStyle(feature, resolution)[0];
		origin.getStroke().setWidth(3);
		origin.getStroke().setColor('#333');
		origin.setZIndex(100);

		return [origin];
	}


	static activeLineStyle(feature: Feature, resolution: number): Style[] {

		let styles = StyleService.lineStyle(feature, resolution);

		let radius = 6 / resolution;
		if(radius < 2) { radius = 2; }
		else if(radius > 6) { radius = 6; }

		radius *= 3;
		styles[0].getStroke().setWidth(radius);
		styles[0].setZIndex(100);

		return styles;
	}



	static lineStyle(feature: Feature, resolution: number): Style[] {
		let lineStyleCache = {};

		let props = feature.getProperties();

		let color ='#AEAEAE';
		let zIndex = 1;

		if(props.active) {
			color = '#08827A';
			zIndex = 2;
		}

		// @ts-ignore
		if(!lineStyleCache[resolution + '-' + color + '-' + zIndex]) {

			let radius = 8 / resolution;
			if(radius < 4) { radius = 4; }
			else if(radius > 8) { radius = 8; }

			let bg1 = new Style({
				stroke: new Stroke({
					color: 'rgba(0, 0, 0, .1)',
					width: radius * 1.4
				})
			});

			let bg2 = new Style({
				stroke: new Stroke({
					color: 'rgba(0, 0, 0, .1)',
					width: radius * 1.3
				})
			});

			let bg3 = new Style({
				stroke: new Stroke({
					color: 'rgba(90, 90, 90, .1)',
					width: radius * 1.2
				})
			});

			let line = new Style({
				stroke: new Stroke({
					color: color,
					width: radius,
				}),
				zIndex: zIndex
			});

			let label = new Style({
				text: new Text({
					font: '11px Roboto, sans-serif',
					textAlign: 'center',
					fill: new Fill({
						color: 'white'
					}),
					backgroundFill: new Fill({
						color: color
					}),
					backgroundStroke: new Stroke({
						color: '#f9f9f9',
						width: 0.5
					}),
					padding: [1, 2, 1, 2]
				}),
				zIndex: zIndex + 1
			});

			// @ts-ignore
			lineStyleCache[resolution + '-' + color + '-' + zIndex] = [bg1, bg2, bg3, line, label];
		}

		// @ts-ignore
		let style = lineStyleCache[resolution + '-' + color + '-' + zIndex];
		style[4].getText().setText(props.label + '');
		return style;
	}

	static polygonStyle(feature: any, resolution: any) {

		const props = feature.getProperties();
		const color = props.color;

		let fillColor = color;

		let style = new Style({
			stroke: new Stroke({
				color: 'rgba(134,134,134,0.8)',
				width: 2
			}),
			fill: new Fill({
				color: fillColor,
			})
		});
		return [style];
	}



	// new styles with types

	public concessionLineStyle(feature: Feature, resolution: number): StyleLike {
		let _style = new Style({
			stroke: new Stroke({
				color: '#333',
				width: 2,
			}),
			zIndex: 10
		});

		let _geom = feature.getGeometry() as MultiLineString;

		let _points = new Style({
			image: new CircleStyle({
				radius: 4,
				fill: new Fill({
					color: 'black',
				}),
			}),
			zIndex: 10,
			geometry: function (feature) {
				return new MultiPoint([_geom.getFirstCoordinate(), _geom.getLastCoordinate()]);
			},
		});

		if(resolution > 100) {
			return [_style];
		}

		if(resolution > 16) {
			return [_points, _style];
		}

		let _label = new Style({
			text: new Text({
				font: '11px Roboto, sans-serif',
				textAlign: 'center',
				fill: new Fill({
					color: 'white'
				}),
				text: feature.get('label'),
				backgroundFill: new Fill({
					color: 'black'
				}),
				backgroundStroke: new Stroke({
					color: '#f9f9f9',
					width: 0.5
				}),
				padding: [1, 2, 1, 2]
			}),
			zIndex: 11
		});

		return [_label, _points, _style];
	}

	public concessionLineStyleActive(feature: Feature, resolution: number): StyleLike {
		let _style = new Style({
			stroke: new Stroke({
				color: '#2196F3',
				width: 2,
			}),
			zIndex: 20
		});

		let _geom = feature.getGeometry() as MultiLineString;

		let _points = new Style({
			image: new CircleStyle({
				radius: 4,
				fill: new Fill({
					color: '#2196F3',
				}),
			}),
			zIndex: 20,
			geometry: function (feature) {
				return new MultiPoint([_geom.getFirstCoordinate(), _geom.getLastCoordinate()]);
			},
		});

		let _label = new Style({
			text: new Text({
				font: '11px Roboto, sans-serif',
				textAlign: 'center',
				fill: new Fill({
					color: 'white'
				}),
				text: feature.get('label'),
				backgroundFill: new Fill({
					color: '#2196F3'
				}),
				backgroundStroke: new Stroke({
					color: '#f9f9f9',
					width: 0.5
				}),
				padding: [1, 2, 1, 2]
			}),
			zIndex: 21
		});

		return [_label, _points, _style];
	}

	public concessionAreaStyle(feature: Feature, resolution: number): Style {
		const color = '#2196F3';

		let style = new Style({
			stroke: new Stroke({
				color: color,
				width: 4
			}),
			fill: new Fill({
				color: 'rgba(0,0,0,0)',
			}),
			zIndex: 20
		});

		return style;
	}

	public municipalitySelectedStyle(feature: Feature, resolution: number): Style {

		let concession_area_ids = feature.getProperties().concession_areas;
		let size = _.size(concession_area_ids);

		let alpha = size > 1 ? 0.5 : 0.2;

		let s = new Style({
			stroke: new Stroke({
				color: 'rgba(33, 150, 243, 1)',
				width: 1.4
			}),
			fill: new Fill({
				color: `rgba(33, 150, 243, ${alpha})`,
			}),
			zIndex: 20
		});

		return s;
	}

	public municipalityStyle(feature: Feature, resolution: number): Style {

		let concession_area_ids = feature.getProperties().concession_areas;
		let size = _.size(concession_area_ids);
		let r, g, b;

		// default
		r = g = b = 158;

		if(size === 0) {
			// r = 211;
			// g = b = 47;
			r = 251;
			g = 192;
			b = 45;
		}
		else if(size > 1) {
			r = g = b = 5;
		}

		let colorStroke = 'rgba('+r+', '+g+', '+b+', 0.5)';
		let colorFill = 'rgba('+r+', '+g+', '+b+', 0.1)';

		let s = new Style({
			stroke: new Stroke({
				color: colorStroke,
				width: 1.4
			}),
			fill: new Fill({
				color: colorFill,
			}),
			zIndex: 10
		});

		return s;
	}

	public boxStyle(feature: Feature, resolution: number): Style {
		return  new Style({
			fill: new Fill({
				color: 'rgba(131,242,235,0.5)'
			})
		});
	}

	public heatMapStyle(feature: Feature, resolution: number): Style {
		let color 	= '#2196F3';
		const value = feature.get('value');

		if(value === 0) {
			color = 'red';
		}

		else if(value < 20) {
			color = 'rgba(131,242,235,0.5)';
		}
		else if(value < 40) {
			color = '#21C2B7';
		}
		else if(value < 60) {
			color = '#08827A';
		}
		else if(value < 80) {
			color = '#09524D';
		}
		else {
			color = '#0A2B29';
		}

		let style = new Style({
			fill: new Fill({
				color: color,
			}),
			zIndex: 20
		});

		return style;
	}

	static cache: Map<string, Style> = new Map<string, Style>();

	static pointStyle(feature: Feature, resolution: number) {

		if(!StyleService.cache.has('point')) {
			let style = new Style({
				image: new CircleStyle({
					radius: 4,
					fill: new Fill({color: '#fff'}),
					stroke: new Stroke({color: '#D6246E', width: 3}),
				})
			});

			StyleService.cache.set('point', style);
		}

		return StyleService.cache.get('point');
	}

	static incidentStyle(feature: Feature, r: number) {
		return new Style({
			image: new CircleStyle({
				radius: 6,
				fill: new Fill({color: '#fff'}),
				stroke: new Stroke({color: '#D6246E', width: 4}),
			})
		});
	}

	static abcMainStyle(feature: Feature, resolution: number) {
		const code = feature.get('abc_code');

		if(!StyleService.cache.has(code)) {
			let color = feature.get('color');

			let style = new Style({
				image: new CircleStyle({
					radius: 4,
					fill: new Fill({color: color}),
					stroke: new Stroke({color: '#fff', width: 1}),
				})
			});

			StyleService.cache.set(code, style);
		}

		return StyleService.cache.get(code);
	}


	// not used for now
	static definedStyle = {
		'incidents': {
			symbol: {
				symbolType: 'triangle',
				size: 18,
				color: ['interpolate', ['linear'], ['get', 'abc_code'],
					'A', '#D6246E', 'B', '#9656D0', 'C', '#E89C3F', 'P', '#3272D9'],
				opacity: 0.85,
				rotateWithView: true,
			}
		},
		'advanced_incidents': {
			symbol: {
				symbolType: 'circle',
				size: [
					"interpolate",
					[
						"exponential",
						2
					],
					[
						"zoom"
					],
					14, // level
					6,
					15, // level
					10
				],
				color: [
					'interpolate',
					[
						'linear'
					],
					[
						'get',
						'abc_code'
					],
					'A', 'red',
					'B', 'blue',
					'C', 'green',
					'P', 'pink'
				],
				opacity: 0.9,
				rotateWithView: true,
			}
		}
	}

}
