import {Injectable} from '@angular/core';
import {Filter, FilterField} from "../types/sherpa";
import _ from "lodash";

@Injectable({
	providedIn: 'root'
})
export class CaptionService {

	constructor() {
	}

	getButtonLabel(allFilters: Filter[], field: FilterField) {
		const filter = _.find(allFilters, ['key', field.key]);
		if(_.isUndefined(filter)) {
			return 'no matching filter??';
		}

		// handle special cases
		if(filter.type.isMultiSelect) {
			if(filter.type.customType === 'tree') {
				return this._getTreeLabel(filter, field);
			}
			else if(filter.type.customType === 'date') {
				return this._getDateLabel(filter, field);
			}

			return this._getMultiLabel(filter, field);
		}

		// single value
		return field.expectedValue;
	}

	_getDateLabel(filter: Filter, field: FilterField) {

		if(_.has(field.expectedValue, 'maxValue') && _.has(field.expectedValue, 'minValue')) {
			const min = field.expectedValue.minValue;
			const max = field.expectedValue.maxValue;

			if(min === null && max === null) {
				return `${filter.title} (niets)`;
			}
			else if(min === null && max !== null) {
				return `alle data tot ${new Date(new Date(max)).toLocaleDateString("nl-NL")}`;
			}
			else if(min !== null && max === null) {
				return `${new Date(min).toLocaleDateString("nl-NL")} - vandaag`;
			}
			else {
				return `${new Date(min).toLocaleDateString("nl-NL")} - ${new Date(max).toLocaleDateString("nl-NL")}`;
			}
		}

		return this._getTreeLabel(filter, field);
	}

	_getTreeLabel(filter: Filter, field: FilterField) {
		type State = {
			type: 'none'|'partial'|'all',
			label: string,
			total: number,
			selected: number
		}
		let states: State[] = [];

		_.forEach(filter.values, root => {
			const childValues = _.map(root.value, 'value') as string[];
			let counter = 0;

			_.forEach(field.expectedValue, item => {
				if(_.includes(childValues, item)) {
					counter++;
				}
			});

			let state: State = {type: 'none', label: root.label, selected: counter, total: childValues.length};

			if(counter === childValues.length) {
				state.type = 'all';
			}
			else if(counter > 0) {
				state.type = 'partial';
			}

			states.push(state);
		});

		let all = _.filter(states, ['type', 'all']);
		let partials = _.filter(states, ['type', 'partial']);

		if(field.expectedValue.length === 1) {
			let result = `${filter.title} (${field.expectedValue[0]})`;

			// for abc_code return the value (not the label)
			if(_.includes(['abc_code', 'date'], filter.key)) {
				return result;
			}

			_.forEach(filter.values, root => {
				const obj = _.find(root.value, ['value', field.expectedValue[0]]) as any;

				if(obj !== undefined) {
					result = `${filter.title} (${obj.label})`;
					return false; // break loop
				}

				return true;
			});

			return result;
		}
		else if(partials.length === 0 && all.length === 0) {
			return `${filter.title} (niets)`;
		}
		else if(partials.length === 0 && filter.values.length === all.length) {
			return `${filter.title} (alles)`;
		}

		if(partials.length === 0) {
			if(all.length === 1) {
				return all[0].label;
			}
			else if(all.length === 2) {
				return `${all[0].label} & ${all[1].label}`;
			}

			let sum = _.sumBy(all, 'selected');
			return `${all[0].label} +${sum - all[0].selected}`;
		}
		else if(partials.length === 1) {
			if(all.length === 0) {
				return `${partials[0].label} (${partials[0].selected}/${partials[0].total})`;
			}
			else if(all.length === 1) {
				return `${all[0].label} +${partials[0].selected}`;
			}

			let sum1 = _.sumBy(all, 'selected');
			let sum2 = _.sumBy(partials, 'selected');
			return `${all[0].label} +${(sum1 + sum2) - all[0].selected}`;
		}
		else {
			let sum = _.sumBy(partials, 'selected');
			if(all.length === 0) {
				return `${filter.title} (${sum})`;
			}
			else if (all.length === 1) {
				return `${all[0].label} +${sum}`;
			}

			let sum1 = _.sumBy(all, 'selected');
			let sum2 = _.sumBy(partials, 'selected');
			return `${all[0].label} +${(sum1 + sum2) - all[0].selected}`;
		}
	}

	_getMultiLabel(filter: Filter, field: FilterField) {

		if(filter.values === null) {
			return `${filter.title} (${field.expectedValue.length})`;
		}

		if(field.expectedValue.length === 1) {
			const obj = _.find(filter.values, ['value', field.expectedValue[0]]);
			if(obj === undefined) {
				return 'todo';
			}
			const label = filter.key === 't_code' ? obj.value : obj.label;
			return `${filter.title} (${label})`;
		}
		else if(field.expectedValue.length === 0) {
			return `${filter.title} (niets)`;
		}
		else if(field.expectedValue.length === filter.values.length) {
			return `${filter.title} (alles)`;
		}

		const obj = _.find(filter.values, ['value', field.expectedValue[0]]);
		if(obj === undefined) {
			return 'todo';
		}
		const label = filter.key === 't_code' ? obj.value : obj.label;
		return `${filter.title} (${label} +${field.expectedValue.length - 1})`;
	}



	getCaption() {
		// todo
	}
}
