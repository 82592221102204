import {Injectable} from '@angular/core';
import _ from "lodash";
import {SelectItem} from "primeng/api";
import {StoredConfig} from "../../types/sherpa";

@Injectable({
	providedIn: 'root'
})
export class StaticDataService {

	constructor() {
	}

	getAlphabet(): string {
		return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	}

	getTransportModes(): SelectItem[] {
		const dropdownItems: SelectItem[] = [
			{label: '-- Selecteer een modaliteit --', value: null}
		];

		_.forEach(this._getTransportModes(), (m: string) => {
			dropdownItems.push({label: m, value: m});
		})

		return dropdownItems;
	}

	private _getTransportModes(): string[] {
		return [
			"bus",
			"bus,tram",
			"bus,tram,metro",
			"bus,tram,metro,ferry",
			"tram,metro",
			"metro",
			"tram",
			"train",
			"ferry"
		];
	}

	static getABCCodes(): any[] {
		return [
			{label: 'A1a'},
			{label: 'A1b'},
			{label: 'A2a'},
			{label: 'A2b'},
			{label: 'A3a'},
			{label: 'A3b'},
			{label: 'A4a'},
			{label: 'A4b'},
			{label: 'A5'},
			{label: 'A6'},
			{label: 'A7'},
			{label: 'B1a'},
			{label: 'B1b'},
			{label: 'B2'},
			{label: 'B3'},
			{label: 'B4'},
			{label: 'B5'},
			{label: 'B6'},
			{label: 'C1'},
			{label: 'C2'},
			{label: 'C3'}
		];
	}

	static getMonths(): any[] {
		return [
			{label: 'Januari'},
			{label: 'Februari'},
			{label: 'Maart'},
			{label: 'April'},
			{label: 'Mei'},
			{label: 'Juni'},
			{label: 'Juli'},
			{label: 'Augustus'},
			{label: 'September'},
			{label: 'Oktober'},
			{label: 'November'},
			{label: 'December'}
		];
	}

	getColorPalette() {
		let list: string[] = [];
		let map = this.getColorPaletteMap();

		for (let [key, value] of map) {
			list = _.concat(list, value);
		}

		return list;
	}

	getColorPaletteMap(): Map<string, string[]> {
		let map = new Map<string, string[]>();

		let green = [
			"#83F2EB",
			"#21C2B7",
			"#08827A",
			"#09524D",
			"#0A2B29"
		];
		let blue = [
			"#CFE0FC",
			"#84B1FA",
			"#3272D9",
			"#114599",
			"#15233B"
		];
		let purple = [
			"#EAD9FA",
			"#C79BF2",
			"#9656D6",
			"#642B9E",
			"#371C52"
		];
		let red = [
			"#FAD4E4",
			"#F78BB8",
			"#D6246E",
			"#8A1244",
			"#421527"
		];
		let orange = [
			"#FAD8AF",
			"#E89C3F",
			"#AD5F00",
			"#693D07",
			"#33210C"
		];

		map.set('orange', orange);
		map.set('green', green);
		map.set('blue', blue);
		map.set('purple', purple);
		map.set('red', red);

		return map;
	}

	getConcessionTypes(): SelectItem[] {
		const dropdownItems: SelectItem[] = [
			{label: '-- Selecteer een type --', value: null},
			{label: 'Gebied', value: 'area'},
			{label: 'Lijn', value: 'line'}
		];

		return dropdownItems;
	}

	getDataOwnerTypes(): SelectItem[] {
		const dropdownItems: SelectItem[] = [
			{label: '-- Selecteer een haltedatabeheer --', value: null},
			{label: 'Wegbeheerder', value: 'ROOW'},
			{label: 'Vervoerder', value: 'PT'},
			{label: 'OV-autoriteit', value: 'AUTH'},
			{label: 'Gemeente', value: 'MUNI'},
			{label: 'Externe partij', value: 'EXT'}
		];

		return dropdownItems;
	}

	getAnnotations(): SelectItem[] {
		const dropdownItems: SelectItem[] = [
			{label: '-- Selecteer --', value: null},
			{label: 'Goedgekeurd', value: 'valid'},
			{label: 'Afgekeurd', value: 'invalid'},
			{label: 'Nog niet beoordeeld', value: 'tobecheckd'}
		];
		return dropdownItems;
	}
}
