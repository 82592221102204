
<div [style.cursor]="filter.values?.length ? 'pointer' : 'default'" (click)="filter.values?.length && overLay.toggle($event);">
	<div class="fake-dropdown p-jc-between p-unselectable-text">

		<span>{{filter.title}} <span *ngIf="fieldLabel !== null" class="p-text-bold">{{fieldLabel}}</span></span>



		<ng-container *ngIf="filter.values?.length === 0">
			<span class="p-text-italic p-text-light" style="color: #959595;">geen opties gevonden</span>
		</ng-container>

		<div class="fake-dropdown-icon p-ai-center p-d-flex pi"
			 *ngIf="filter.values?.length"
			 [ngClass]="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></div>
	</div>
</div>

<p-overlayPanel #overLay appendTo="body"
				[styleClass]="isTree ? 'fake-dropdown-overlay p-shadow-0 p-mt-0' : 'fake-dropdown-overlay no-overflow p-shadow-0 p-mt-0'"
				[style]="{width: '608px'}"
				(onShow)="collapsed = false;"
				(onHide)="collapsed = true;"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>

		<div class="p-field p-m-3">
			<p-selectButton [options]="options" [(ngModel)]="isTree"></p-selectButton>
		</div>

		<ng-container *ngIf="filter.values === null">
			<div class="p-text-italic p-text-light p-p-5" style="color: #959595;">
					<span>
						Gebruik overige filters om de selectie te verfijnen, zodat een lijst getoond wordt. Hiervoor dient u een vinkje te zetten bij 'Toon alleen relevante filteropties'.
						U kunt ook het zoekveld gebruiken.
					</span>
			</div>
		</ng-container>

		<p-tree selectionMode="checkbox" filterPlaceholder="Typ om te zoeken.."
				(onNodeSelect)="done()"
				(onNodeUnselect)="done()"
				[filter]="true"
				[hidden]="!isTree"
				[value]="treeNodes"
				[(selection)]="selectedNodes"></p-tree>

		<div [hidden]="isTree">
			<div class="p-fluid p-p-3">
				<div class="p-field">
					<label [for]="'date1'">Van</label>
					<p-calendar inputId="date1"
								dateFormat="dd-mm-yy"
								placeholder="Kies een datum (leeg betekent alles tot)"
								(onClose)="done()"
								[maxDate]="date2"
								[(ngModel)]="date1"
								[showWeek]="true"
								[showButtonBar]="true"></p-calendar>
				</div>
				<div class="p-field">
					<label [for]="'date2'">Tot</label>
					<p-calendar inputId="date2"
								dateFormat="dd-mm-yy"
								placeholder="Kies een datum (leeg betekent vandaag)"
								(onClose)="done()"
								[minDate]="date1"
								[(ngModel)]="date2"
								[showWeek]="true"
								[showButtonBar]="true"></p-calendar>
				</div>
			</div>
		</div>

	</ng-template>
</p-overlayPanel>
