
<div [style.cursor]="'pointer'" (click)="overLay.toggle($event);">
	<div class="fake-dropdown p-jc-between p-unselectable-text">

		<span>Gebied <span *ngIf="fieldLabel !== null" class="p-text-bold">{{fieldLabel}}</span></span>

		<div class="fake-dropdown-icon p-ai-center p-d-flex pi"
			 [ngClass]="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></div>
	</div>
</div>

<p-overlayPanel #overLay appendTo="body" styleClass="fake-dropdown-overlay p-shadow-0 p-mt-0" [style]="{width: '608px'}"
				(onShow)="collapsed = false; initMap();"
				(onHide)="collapsed = true;"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>

		<div class="p-p-3">
			<p class="p-mt-0">Gebruik <u>Shift+Slepen</u> om een gebied in te tekenen of om te bewerken.</p>

			<div id="extent-map" class="map" style="border: 1px solid #c7c7c7;"></div>

			<div *ngIf="selectedValue !== null">
				<button pButton pRipple (click)="clear();"
						class="p-button-outlined p-button-secondary p-button-sm p-mt-3"
						label="Verwijder gebied">
				</button>
			</div>
		</div>

	</ng-template>
</p-overlayPanel>
