
<div *ngIf="title" class="p-pb-1">
	<label>{{title}}</label>
</div>

<div [style.cursor]="values.length ? 'pointer' : 'default'" (click)="values.length && overLay.toggle($event);">
	<div class="fake-dropdown p-jc-between p-unselectable-text" #dropdown>

		<ng-container *ngIf="values.length > 0 && selectedOption !== null">
			<span class="p-text-bold" *ngIf="selectedOption.icon"><i class="fas" [ngClass]="selectedOption.icon"></i></span>
			<span class="p-text-bold" *ngIf="!selectedOption.icon">{{selectedOption.label}}</span>
		</ng-container>

		<ng-container *ngIf="values.length === 0 && selectedOption === null">
			<span class="p-text-italic p-text-light" style="color: #959595;">Verfijn andere filters</span>
		</ng-container>

		<div class="fake-dropdown-icon p-ai-center p-d-flex pi"
			 *ngIf="values.length"
			 [ngClass]="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></div>
	</div>
</div>

<p-overlayPanel #overLay appendTo="body" styleClass="fake-dropdown-overlay p-shadow-0 p-mt-0" [style]="{width: dropdown.clientWidth + 'px'}"
				(onShow)="collapsed = false;"
				(onHide)="collapsed = true;"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>
		<div class="p-p-3">
			<div *ngFor="let option of values" class="p-field-checkbox">
				<p-radioButton [inputId]="option.value" name="option"
							   [value]="option"
							   (onClick)="done()"
							   [(ngModel)]="selectedOption"></p-radioButton>

				<label [for]="option.value">
					<span *ngIf="option.icon"><i [ngClass]="option.icon"></i></span>
					<span *ngIf="!option.icon">{{option.label}}</span>
				</label>
			</div>
		</div>
	</ng-template>
</p-overlayPanel>
