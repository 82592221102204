import {Pipe, PipeTransform} from '@angular/core';
import _ from "lodash";

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {

	transform(items: any[], searchTerm: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		searchTerm = searchTerm.toLowerCase();

		return _.filter(items, item => {

			let self = item.label.toLowerCase().indexOf(searchTerm) !== -1;
			if(self) {
				return true;
			}

			if(!_.isNil(item.parentLabel)) {
				let parent = item.parentLabel.toLowerCase().indexOf(searchTerm) !== -1;
				return parent;
			}

			if(_.isArray(item.value)) {
				let childList = item.value.filter((child: any) => child.label.toLowerCase().indexOf(searchTerm) !== -1);
				return childList.length;
			}

			return false;
		});

	}

}
