import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter, FilterField, QueryConfiguration} from "../../../types/sherpa";
import {Observable, Subscription} from "rxjs";
import _ from "lodash";
import {Map as OLMap} from "ol";
import VectorLayer from "ol/layer/Vector";
import ExtentInteraction, {ExtentEvent, Options} from 'ol/interaction/Extent';
import {shiftKeyOnly} from "ol/events/condition";
import {MapService} from "../../../services/map.service";
import {StyleService} from "../../../services/style.service";
import {Extent} from "ol/extent";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {Fill, Style} from "ol/style";

@Component({
	selector: 'base-filter-extent',
	templateUrl: './filter-extent.component.html',
	styleUrls: ['./filter-extent.component.scss']
})
export class FilterExtentComponent implements OnInit {

	@Input() panelObservable: Observable<AnalysePanel>;
	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();
	subscription: Subscription;

	collapsed: boolean = true;
	selectedValue: any = null;
	fieldLabel: string = null;
	extentChangedTimerId: number = -1;

	// openlayers
	map: OLMap;
	layer: VectorLayer;
	extentInteraction: ExtentInteraction = null;

	constructor(private cd: ChangeDetectorRef, public styles: StyleService, public mapService: MapService) {
	}

	ngOnInit(): void {
		this.subscription = this.panelObservable.subscribe((panel: AnalysePanel) => {
			if(panel === null) {
				return;
			}

			this.setSelectedValues(panel.extent);
			this.cd.markForCheck();
		});
	}

	initMap() {
		_.delay(() => {
			this.map = this.mapService.createMap('extent-map');

			this.extentInteraction = new ExtentInteraction({
				condition: shiftKeyOnly,
				boxStyle: (f, r) => {
					return new Style({
						fill: new Fill({
							color: 'rgba(47,55,54,0.5)'
						})
					});
				}
			} as Options);

			this.extentInteraction.on('extentchanged', (event: ExtentEvent) => {
				this.selectedValue = event.extent;

				// remove previous delay
				window.clearTimeout(this.extentChangedTimerId);

				// sent to parent after few seconds
				this.extentChangedTimerId = _.delay(() => {
					this.done();
				}, 1000);
			});

			if(this.selectedValue !== null) {
				this.extentInteraction.setExtent(this.selectedValue);
				this.extentInteraction.changed();

				this.mapService.fit(this.map, this.selectedValue as Extent, {padding: [50,50,50,50]});
			}

			this.map.addInteraction(this.extentInteraction);

		}, 0);
	}

	done() {
		// trigger parent
		this.onFilterFieldChanged.emit({key: 'extent', expectedValue: this.selectedValue});

		// update the displayed label
		this.updateFieldLabel();
	}

	clear() {
		this.selectedValue = null;
		this.extentInteraction.setExtent(this.selectedValue);
		this.extentInteraction.changed();
	}

	setSelectedValues(extent: Extent) {
		this.selectedValue = extent;

		if(this.extentInteraction !== null) {
			this.extentInteraction.setExtent(this.selectedValue);
			this.extentInteraction.changed();
		}

		// update the displayed label
		this.updateFieldLabel();
	}

	updateFieldLabel() {
		this.fieldLabel = null;

		if(this.selectedValue !== null) {
			this.fieldLabel = '(box geselecteerd)';
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
