import {SherpaJson} from "./SherpaJson";

export interface BackendFunctions {
	[key: string]: <T>(...args: any[]) => Promise<T>;
}

export class Sherpa<T=BackendFunctions> {
	json: SherpaJson;
	api: T;

	constructor(json: SherpaJson) {
		this.json = json as SherpaJson;
		this.api = {} as T;
	}
}
