import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter, FilterField, QueryConfiguration} from "../../../types/sherpa";
import {Observable, Subscription} from "rxjs";
import _ from "lodash";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";

@Component({
	selector: 'base-filter-single',
	templateUrl: './filter-single.component.html',
	styleUrls: ['./filter-single.component.scss']
})
export class FilterSingleComponent implements OnInit {

	@Input() filter: Filter;
	@Input() filterFieldsConfig: Observable<AnalysePanel>;
	subscription: Subscription;

	// callback to parent
	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();

	collapsed: boolean = true;
	selectedOption: any = null;
	fieldLabel: string = null;

	constructor(private cd: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.subscription = this.filterFieldsConfig.subscribe(panel => {
			if(panel === null) {
				return;
			}

			this.setSelectedValues(panel.andFilterFields);
			this.cd.markForCheck();
		});
	}

	done() {
		let singleValue = this.selectedOption !== null ? this.selectedOption.value : null;

		// trigger parent
		this.onFilterFieldChanged.emit({key: this.filter.key, expectedValue: singleValue});

		this.updateFieldLabel();
	}

	setSelectedValues(newFilterFieldConfig: FilterField[]) {
		let matchedField = _.find(newFilterFieldConfig, ['key', this.filter.key]) || {} as FilterField;

		this.selectedOption = _.find(this.filter.values, ['value', matchedField.expectedValue]) || null;

		// update the displayed label
		this.updateFieldLabel();
	}

	updateFieldLabel() {
		this.fieldLabel = null;

		if(this.selectedOption !== null) {
			this.fieldLabel = `(${this.selectedOption.label} geselecteerd)`;
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
