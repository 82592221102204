<ng-container *ngIf="comparison !== null">
	<div class="p-grid p-nogutter p-pb-3">
		<p class="p-mt-1">
			Vergelijk twee perioden met elkaar. Dit kan alleen nuttig gebruikt worden indien het datum filter
			overenkomstig gezet is.
			Om kwartaal uit een speciek jaar met een ander jaar te verlijken, kan het filter gebruikt worden. Anders
			worden de geselecteerde kwartalen
			uit alle beschikbare jaren gebruikt. Ditzelfde geldt voor maanden.
		</p>
		<base-filter-basic
			class="p-col-7"
			[panelObservable]="panelObservable"
			[values]="kindValues"
			title="Type periode:"
			path="comparison.key"
			(onFilterFieldChanged)="filterChanged($event)">
		</base-filter-basic>
	</div>
	<div class="p-grid p-nogutter p-pb-3" *ngIf="refreshed">
		<base-filter-basic
			class="p-col-4"
			[panelObservable]="panelObservable"
			[values]="yearComparisonValues"
			title="Vergelijk (a)"
			path="comparison.a"
			(onFilterFieldChanged)="filterChanged($event)">
		</base-filter-basic>
		<base-filter-basic
			class="p-col-4" style="padding-left: 1px; padding-right: 1px;"
			[panelObservable]="panelObservable"
			[values]="yearComparisonValues"
			title="Met (b)"
			path="comparison.b"
			(onFilterFieldChanged)="filterChanged($event)">
		</base-filter-basic>
		<base-filter-basic
			class="p-col-4"
			[panelObservable]="panelObservable"
			[values]="asPercValues"
			title="Toon"
			path="comparison.percMode"
			(onFilterFieldChanged)="filterChanged($event)">
		</base-filter-basic>
	</div>
</ng-container>