import {Injectable} from '@angular/core';
import {BackendFunctions, Sherpa} from "../../classes/Sherpa";
import {sherpa, util} from "../../../services";
import {
	ChartDataWithConfig,
	GroupOption,
	MultiChartDataWithConfig,
	StoredConfig,
	StoredConfigsWrapper
} from "../../types/sherpa";
import {SherpaError} from "../../classes/SherpaError";
import {AnalysePanel} from "../../classes/models/AnalysePanel";
import {SessionService} from "../../modules/auth/services/session.service";

@Injectable({
	providedIn: 'root'
})
export class ConfigsService {

	private api: BackendFunctions = {};


	constructor(private session: SessionService) {
		sherpa.api$.subscribe((sherpa: Sherpa) => {
			this.api = sherpa.api;
		});
	}

	saveConfig(filter: StoredConfig): Promise<StoredConfig> {
		return this.api.saveStoredConfig<StoredConfig>(this.session.token(), filter)
			.catch((e: SherpaError) => util.handleError(e));
	}

	deleteConfig(stored_config_id: number): Promise<void> {
		return this.api.deleteStoredConfig<void>(this.session.token(), stored_config_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getStoredConfigs(): Promise<StoredConfigsWrapper> {
		return sherpa.api$.toPromise().then((sherpa: Sherpa) => {;
			return sherpa.api.getStoredConfigs<StoredConfigsWrapper>(this.session.token())
				.catch((e: SherpaError) => util.handleError(e));
		})
	}

	getPanelsForConfigId(stored_config_id: number): Promise<MultiChartDataWithConfig> {
		return this.api.getPanelsForConfigId<MultiChartDataWithConfig>(this.session.token(), stored_config_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getPanelsForSharingCode(sharing_code: string): Promise<MultiChartDataWithConfig> {
		return this.api.getPanelsForSharingCode<MultiChartDataWithConfig>(this.session.token(), sharing_code)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getChartDataForConfig(stored_config_id: number): Promise<ChartDataWithConfig> {
		return this.api.getChartDataForConfig<ChartDataWithConfig>(this.session.token(), stored_config_id)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getUserDefaultFilters(): Promise<any> {
		return this.api.getUserDefaultFilters<any>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	getLoggingAgreement(): Promise<boolean> {
		return this.api.getLoggingAgreement<boolean>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	setLoggingAgreement(value: boolean): Promise<void> {
		return this.api.setLoggingAgreement<void>(this.session.token(), value)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getYearlyReminder(): Promise<boolean> {
		return this.api.getYearlyReminder<boolean>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	setYearlyReminder(value: boolean): Promise<void> {
		return this.api.setYearlyReminder<void>(this.session.token(), value)
			.catch((e: SherpaError) => util.handleError(e));
	}

	getUserPreferences(): Promise<any> {
		return this.api.getUserPreferences<any>(this.session.token())
			.catch((e: SherpaError) => util.handleError(e));
	}

	setUserPreferences(config: any): Promise<any> {
		return this.api.setUserPreferences<any>(this.session.token(), config)
			.catch((e: SherpaError) => util.handleError(e));
	}

	storeUserDefaultFilter(defaultFilters: any): Promise<void> {
		return this.api.storeUserDefaultFilter<any>(this.session.token(), defaultFilters)
			.catch((e: SherpaError) => util.handleError(e));
	}

	searchFilter(filterKey: string, searchQuery: string): Promise<any> {
		return this.api.searchFilter<any>(this.session.token(), filterKey, searchQuery)
			.catch((e: SherpaError) => util.handleError(e));
	}

	saveTempStoredConfig(notSavedConfig: StoredConfig): Promise<StoredConfig> {
		return this.api.saveTempStoredConfig<StoredConfig>(this.session.token(), notSavedConfig)
			.catch((e: SherpaError) => util.handleError(e));
	}

	sleep(ms : number): Promise<any> {
		return this.api.sleep<any>(ms)
			.catch((e: SherpaError) => util.handleError(e));
	}

	sendSubscribedStoredConfig(stored_config_id: number): Promise<void> {
		return this.api.sendSubscribedStoredConfig<void>(this.session.token(), stored_config_id)
			.catch((e: SherpaError) => util.handleError(e));
	}
}
