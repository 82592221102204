

<div class="p-field p-fluid">
	<label for="link" class="">Link</label>
	<div class="p-inputgroup">
		<input id="link" #pathInput type="text" pInputText placeholder="Link" aria-describedby="link-help" [value]="path" readonly>
		<button pButton pRipple type="button" [label]="buttonText" (click)="copy(pathInput)"></button>
	</div>
</div>

