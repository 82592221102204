<p-toast position="top-right" key="dova-toast"
		 [baseZIndex]="18000"
		 [preventOpenDuplicates]="true">
	<ng-template let-message pTemplate="message">
		<div class="">
			<h1 class="p-mt-1 p-mr-6" *ngIf="message.summary">{{message.summary}}</h1>
			<p class="p-m-0" *ngIf="message.detail">{{message.detail}}</p>
		</div>
	</ng-template>
</p-toast>

<base-app-loader></base-app-loader>

<div class="page">
	<div class="p-grid p-d-print-none p-nogutter top-menu" *ngIf="!hideAll">
		<div class="p-col-fixed" style="width:264px">
			<div class="p-d-flex dova-brand">
				<img src="assets/img/dova--icoon.svg">
				<a class="p-pl-3" routerLink="/analyses/nieuw">DOVA<span>SVOVDA</span></a>
			</div>
		</div>
		<div class="p-col">
			<p-toolbar styleClass="p-p-0">
				<div class="p-toolbar-group-left p-unselectable-text">
					<button class="p-button p-button-rounded p-button-text p-button-secondary p-button-icon-only"
							[pTooltip]="'Menu ' + (global.menu ? 'inklappen' : 'uitklappen')" tooltipPosition="right"
							(click)="global.menu = !global.menu;">

						<ibm-icon-caret-left *ngIf="global.menu" size="20" className="p-button-icon" [style.fill]="'white'" [style.height]="'1.25rem'" [style.width]="'1.25rem'" [style.marginRight]="'-.45rem'"></ibm-icon-caret-left>
						<ibm-icon-menu size="24" className="p-button-icon" [style.fill]="'white'" [style.height]="'1.5rem'"></ibm-icon-menu>
						<ibm-icon-caret-right *ngIf="!global.menu" size="20" className="p-button-icon" [style.fill]="'white'" [style.height]="'1.25rem'" [style.width]="'1.25rem'" [style.marginLeft]="'-.45rem'"></ibm-icon-caret-right>

					</button>
				</div>
				<div class="p-toolbar-group-right p-pr-3">
					<ng-container *ngIf="global.loginUser">
						<div class="profile-dropdown" (click)="userMenu.toggle($event)">
							<label class="p-unselectable-text p-pr-2">{{global.loginUser.email}}</label>
							<ibm-icon-user-avatar-filled-alt size="24" [style.fill]="'white'" [style.height]="'1.5rem'"></ibm-icon-user-avatar-filled-alt>
						</div>

						<p-menu #userMenu [style]="{'width': '16rem'}" [popup]="true" [model]="accountItems"></p-menu>
					</ng-container>
				</div>
			</p-toolbar>

		</div>
	</div>
	<div class="p-grid p-nogutter main-grid">
		<ng-container *ngIf="!hideAll && reloaded">
			<div class="p-col-fixed p-d-print-none" [ngClass]="{'hide-menu' : !global.menu}">
				<p-panelMenu [model]="items" [styleClass]="'main-menu'"></p-panelMenu>
			</div>
		</ng-container>
		<div class="p-col" [ngClass]="{'as-dashboard': !hideAll}">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>


<!-- MODALS -->

<p-dialog [visible]="global.loginModal"
		  [baseZIndex]="1500"
		  styleClass="dova-login-dialog"
		  maskStyleClass="dova-backdrop"
		  [showHeader]="true"
		  header="Login DOVA - SVOVDA"
		  [style]="{'width':'480px'}"
		  [draggable]="false"
		  [closeOnEscape]="false"
		  [closable]="false"
		  [autoZIndex]="true"
		  [modal]="true"
		  [blockScroll]="true">
	<base-login *ngIf="global.loginModal"></base-login>
</p-dialog>

<p-dialog [(visible)]="showDefaultFiltersDialog"
		  [style]="{'width': '45rem'}"
		  [position]="'top'"
		  [baseZIndex]="1400"
		  header="Voorkeur KPI's instellen"
		  styleClass=""
		  [showHeader]="true"
		  [draggable]="true"
		  [closeOnEscape]="true"
		  [closable]="true"
		  [autoZIndex]="true"
		  [modal]="true"
		  [blockScroll]="true">
	<base-my-default-filters *ngIf="showDefaultFiltersDialog"
							 (onChange)="defaultFilters = $event;"
							 (onClose)="showDefaultFiltersDialog = false;"
							 [defaultFilters]="defaultFilters"></base-my-default-filters>

	<ng-template pTemplate="footer">
		<div class="p-d-flex p-ai-center p-pt-5">
			<div class="p-field-checkbox p-unselectable-text p-mr-auto">
				<p-checkbox [(ngModel)]="defaultFilters.disableAtLogin" [binary]="true" inputId="dis"></p-checkbox>
				<label [for]="'dis'">Venster niet meer tonen</label>
				<i class="fa fa-info-circle p-ml-1"
				   tooltipPosition="bottom"
				   tooltipZIndex="2500"
				   pTooltip="Als u voor deze optie kiest dan kunt u dit later onder 'Account'->'Concessie voorkeuren' alsnog instellen."></i>
			</div>

			<button pButton pRipple type="button" label="Sluiten"
					class="p-button-secondary p-button-text p-button-rounded p-mr-2"
					(click)="showDefaultFiltersDialog = false;"></button>
			<button pButton pRipple type="button" label="Opslaan" class="p-button-rounded p-text-uppercase"
					(click)="setDefaultFilters()"></button>
		</div>
	</ng-template>
</p-dialog>

<p-dialog [(visible)]="showHelpDialog"
		  [style]="{'width': '30rem'}"
		  [baseZIndex]="1400"
		  header="Contactinfo DOVA"
		  styleClass=""
		  [showHeader]="true"
		  [draggable]="true"
		  [closeOnEscape]="true"
		  [closable]="true"
		  [autoZIndex]="true"
		  [modal]="true"
		  [blockScroll]="true">

	<p class="p-mt-0">
		Heeft u vragen of opmerkingen, dan kunt u contact opnemen via <strong>ovdata@dova.nu</strong> of via <strong>085 500 60 59</strong> (maandag – vrijdag, 8.00 – 17.00 uur).

		Samenwerkingsverband DOVA is u graag van dienst.
	</p>

	<p class="p-mt-4">
		Heeft u vragen betreft de werking van de tool, dan kunt ook onze handleiding downloaden.
	</p>

	<a pButton pRipple type="button"
	   class="p-button-rounded"
	   icon="pi pi-download"
	   label="Download handleiding"
	   [style]="{'text-decoration': 'auto'}"
	   [href]="manualUrl"></a>

	<ng-template pTemplate="footer">
		<div class="p-d-flex">
			<div class="p-ml-auto">
				<button pButton pRipple type="button" (click)="showHelpDialog = false;"
						label="Sluiten"
						class="p-button-secondary p-button-text p-button-rounded"></button>
			</div>
		</div>
	</ng-template>
</p-dialog>

<p-confirmDialog
	[closeOnEscape]="false"
	styleClass="agreeDialog"
	key="agreementDialog"
	maskStyleClass="dova-backdrop"
	[style]="{width: '30rem'}"
	[baseZIndex]="10000">
</p-confirmDialog>

<p-dialog [(visible)]="showUserPrefsDialog"
		  [style]="{'width': '45rem'}"
		  [baseZIndex]="1400"
		  header="Algemene voorkeuren"
		  styleClass=""
		  [showHeader]="true"
		  [draggable]="true"
		  [closeOnEscape]="true"
		  [closable]="true"
		  [autoZIndex]="true"
		  [modal]="true"
		  [blockScroll]="true">

	<h2>Persoonlijke instellingen</h2>
	<div class="p-field-checkbox">
		<p-checkbox name="mail" [(ngModel)]="userPrefs.send_kpi_email" [binary]="true" inputId="mail"></p-checkbox>
		<label [for]="'mail'">Maandelijks een kpi-rapportage ontvangen per e-mail</label>
	</div>

	<ng-template pTemplate="footer">
		<div class="p-d-flex p-ai-center p-pt-5">
			<button pButton pRipple type="button" label="Sluiten"
					class="p-button-secondary p-button-text p-button-rounded p-mr-2 p-ml-auto"
					(click)="showUserPrefsDialog = false;"></button>
			<button pButton pRipple type="button" label="Opslaan" class="p-button-rounded p-text-uppercase"
					(click)="setUserPrefs()"></button>
		</div>
	</ng-template>
</p-dialog>

<p-dialog [(visible)]="showReminder"
		  [style]="{'width': '30rem'}"
		  [baseZIndex]="1400"
		  header="Herinnering: KPI-mails"
		  styleClass=""
		  [showHeader]="true"
		  [draggable]="true"
		  [closeOnEscape]="true"
		  [closable]="true"
		  [autoZIndex]="true"
		  [modal]="true"
		  [blockScroll]="true">

	<p>U ontvangt geen KPI-mails omdat u geen toestemming heeft gegeven voor gegevensopslag.
		Als u geen KPI-mails wilt ontvangen hoeft u niks te doen. Als u wel KPI-mails wilt ontvangen, geef dan
		<button pButton pRipple (click)="getLoggingAgreement(true); showReminder = false" style="padding: 0; color: darkblue" label="toestemming" class="p-button-sm p-button-link px-0"></button>.</p>

	<ng-template pTemplate="footer">
		<div class="flex pt-3">
			<div class="p-field-checkbox">
				<p-checkbox name="read" [(ngModel)]="read" [binary]="true" inputId="read"></p-checkbox>
				<label [for]="'read'">Markeer als gelezen</label>
			</div>
			<button pButton pRipple type="button" label="Sluiten"
					class="p-button-rounded mr-2 ml-auto"
					(click)="closeReminder()"></button>
		</div>
	</ng-template>

</p-dialog>

<p-confirmDialog
	#cd [style]="{width: '36rem'}"
	[baseZIndex]="10000"
	key="trustedDialog"
>
	<p-footer>
		<button pButton pRipple type="button" label="Nee"
				class="p-button-secondary p-button-text p-button-rounded p-mr-2"
				(click)="cd.reject()">
		</button>

		<button pButton pRipple type="button" label="Ja"
				class="p-button-rounded p-button-primary"
				(click)="cd.accept()">
		</button>
	</p-footer>
</p-confirmDialog>
