
<div style="cursor: pointer;" (click)="overLay.toggle($event);">
	<div class="fake-dropdown p-jc-between p-chips">
		<div class="p-chips-multiple-container p-d-flex p-p-0">
			<div *ngFor="let option of selectedOptions; let i = index;" class="p-chips-token">
				<span class="p-unselectable-text">{{option.title}}</span>
				<div class="p-chips-token-icon pi pi-times-circle" *ngIf="selectedOptions.length > 1 || i > 0" (click)="removeGroupBy(option);"></div>
			</div>
		</div>


		<div class="fake-dropdown-icon p-ai-center p-d-flex pi"
			 [ngClass]="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></div>
	</div>

	<p-overlayPanel #overLay appendTo="body" styleClass="fake-dropdown-overlay p-shadow-0 p-mt-0" [style]="{width: '608px'}"
					(onShow)="collapsed = false;"
					(onHide)="collapsed = true;"
					[showCloseIcon]=""
					[dismissable]="true">
		<ng-template pTemplate>
			<div class="p-d-flex p-flex-wrap p-p-3">
				<div class="p-mr-2 p-mb-2" *ngFor="let opt of options">
					<button pButton pRipple (click)="addGroupBy(opt);" [label]="opt.title"
							[ngClass]="{'p-button-outlined': !includes(opt)}"
							class="p-button-sm p-button-rounded p-button-secondary"></button>
				</div>
			</div>
		</ng-template>
	</p-overlayPanel>
</div>
