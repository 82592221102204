
<p class="p-m-0 p-pb-3">Gebruik <u>Shift+Slepen</u> om een gebied in te tekenen of om te bewerken.</p>

<div class="edit-filter-box">
	<div>
		<div id="extent-map" class="map"></div>
	</div>
</div>

<div *ngIf="selectedValue !== null">
	<button pButton pRipple (click)="clear();"
			class="p-button-info p-text-uppercase p-mt-3"
			label="Verwijder">
	</button>
</div>

