import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {FilterField} from "../../../types/sherpa";
import _ from "lodash";
import {SelectItem} from "primeng/api";

@Component({
	selector: 'base-filter-basic',
	templateUrl: './filter-basic.component.html',
	styleUrls: ['./filter-basic.component.scss']
})
export class FilterBasicComponent implements OnInit {

	@Input() panelObservable: Observable<AnalysePanel>;
	@Input() title: string;
	@Input() path: string; // example: comparison.asPerc
	@Input() values: SelectItem[] = [];

	@Output() onFilterFieldChanged: EventEmitter<FilterField> = new EventEmitter<FilterField>();

	subscription: Subscription;
	collapsed: boolean = true;
	selectedOption: any = null;
	fieldLabel: string = null;

	constructor(private cd: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.subscription = this.panelObservable.subscribe(panel => {
			if(panel === null) {
				return;
			}

			if(!_.has(panel, this.path)) {
			//	console.log('error. panel does not have property:', this.path);
				return;
			}

			const value = _.get(panel, this.path);
			this.setSelectedValues(value);
			this.cd.markForCheck();
		});
	}

	done() {
		const value = this.selectedOption !== null ? this.selectedOption.value : null;
		const field = {key: this.path, expectedValue: value} as FilterField;

		// sent to parent
		this.onFilterFieldChanged.emit(field);
	}

	setSelectedValues(value: any) {
		this.selectedOption = _.find(this.values, ['value', value]) || null;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
