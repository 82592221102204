<form [formGroup]="form" (ngSubmit)="submit();" class="dovaForm">
	<div class="p-fluid">

		<p>
			Voer het e-mailadres van uw account in om het wachtwoord te herstellen.
		</p>
		<br/>


		<div class="p-field">
			<label for="user" class="p-text-normal">E-mailadres</label>
			<input id="user" pInputText autofocus autocomplete="email" type="email" formControlName="email"
				   class="p-inputtext-lg" [ngClass]="{'p-error': hasError(controls.email)}" />
			<small id="user-help" class="p-invalid" *ngIf="hasError(controls.email, 'required')">
				E-mailadres is verplicht.
			</small>
			<small id="user-help2" class="p-invalid" *ngIf="hasError(controls.email, 'email')">
				Geen geldig e-mailadres.
			</small>
		</div>

	</div>

	<div class="p-grid p-formgrid p-mt-5">
		<div class="p-col-12 p-text-right">
			<button pButton pRipple type="button" label="Annuleren"
					class="p-button-secondary p-button-text p-button-rounded p-mr-2"
					(click)="ref.destroy()"></button>
			<button pButton pRipple type="submit" label="Bevestigen"
					class="p-button-rounded p-text-uppercase">
			</button>
		</div>
	</div>

</form>