
<div [style.cursor]="filter.values.length ? 'pointer' : 'default'" (click)="filter.values.length && overLay.toggle($event);">
	<div class="fake-dropdown p-jc-between p-unselectable-text">

		<ng-container *ngIf="filter.values.length > 0">
			<span>{{filter.title}} <span *ngIf="fieldLabel !== null" class="p-text-bold">{{fieldLabel}}</span></span>
		</ng-container>

		<ng-container *ngIf="filter.values.length === 0">
			<span class="p-text-italic p-text-light" style="color: #959595;">{{filter.title}} heeft te veel opties, verfijn eerst met andere filters</span>
		</ng-container>

		<div class="fake-dropdown-icon p-ai-center p-d-flex pi"
			 *ngIf="filter.values.length"
			 [ngClass]="collapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></div>
	</div>
</div>

<p-overlayPanel #overLay appendTo="body" styleClass="fake-dropdown-overlay p-shadow-0 p-mt-0" [style]="{width: '608px'}"
				(onShow)="collapsed = false;"
				(onHide)="collapsed = true;"
				[showCloseIcon]=""
				[dismissable]="true">
	<ng-template pTemplate>
		<div class="p-p-3">
			<div *ngFor="let option of filter.values" class="p-field-checkbox">
				<p-radioButton [inputId]="option.value" name="option"
							   [value]="option"
							   (onClick)="done()"
							   [(ngModel)]="selectedOption"></p-radioButton>

				<label [for]="option.value">{{option.label}}</label>
			</div>

			<div class="p-text-right" style="display: inherit;">
				<button pButton pRipple class="p-button-rounded p-button-text p-button-secondary p-button-sm p-mr-1" label="leeg maken" (click)="selectedOption = null"></button>
			</div>
		</div>
	</ng-template>
</p-overlayPanel>
