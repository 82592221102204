import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AnalysePanel} from "../../../classes/models/AnalysePanel";
import {FilterField, GroupOption} from "../../../types/sherpa";
import _ from "lodash";
import {SelectItem} from "primeng/api";
import {PeriodComparison} from "../../../classes/models/PeriodComparison";

@Component({
	selector: 'base-filter-comparison',
	templateUrl: './filter-comparison.component.html',
	styleUrls: ['./filter-comparison.component.scss']
})
export class FilterComparisonComponent implements OnInit {

	@Input() panelObservable: Observable<AnalysePanel>;
	@Output() onComparisonChanged: EventEmitter<PeriodComparison> = new EventEmitter<PeriodComparison>();

	subscription: Subscription;
	comparison: PeriodComparison = null;
	collapsed: boolean = true;
	refreshed: boolean = true;

	asPercValues: SelectItem[];
	kindValues: SelectItem[];
	yearComparisonValues: SelectItem[];

	constructor(private cd: ChangeDetectorRef) {
		this.yearComparisonValues = [];
		this.asPercValues = [
			{label: 'Verschillen', value: "diff"},
			{label: '% (a / b)', value: "diff_perc"},
			{label: '% (a - b / b)', value: "diff_rel"},
		]
		this.kindValues = [
			{label: 'Jaar', value: 'date_year'},
			{label: 'Kwartaal', value: 'date_quarter'},
			{label: 'Maand', value: 'date_month'},
		]
	}

	ngOnInit(): void {

		this.subscription = this.panelObservable.subscribe(panel => {
			if (panel === null) {
				return;
			}

			// next panel, meaning opened sidebar for filtering
			this.setSelectedValues(panel.comparison);
			this.cd.markForCheck();
		});
	}

	setSelectedValues(comparison: PeriodComparison) {

		if (comparison === null) {
			comparison = {a: 0, asPerc: false, b: 0, key: "date_year"} as PeriodComparison;
		}

		this.initValues(comparison.key);
		this.comparison = comparison;
	}

	initValues(kind: string) {
		this.yearComparisonValues = [{
			label: '-',
			value: 0
		}];

		if (kind === 'date_year') {
			for (let y = 2015; y <= (new Date()).getFullYear(); y++) {
				this.yearComparisonValues.push({label: y + '', value: y})
			}
		} else if (kind === 'date_quarter') {
			for (let q = 1; q <= 4; q++) {
				this.yearComparisonValues.push({label: 'K' + q, value: q})
			}
		} else if (kind === 'date_month') {
			for (let q = 1; q <= 12; q++) {
				this.yearComparisonValues.push({label: q + '', value: q})
			}
		}
	}

	filterChanged($event: FilterField) {

		if ($event.key === 'comparison.key') {
			this.comparison.a = 0; // reset
			this.comparison.b = 0; // reset
			this.comparison.key = $event.expectedValue;

			this.onComparisonChanged.emit(this.comparison);
			this.initValues($event.expectedValue);

			this.refreshed = false;
			this.cd.detectChanges();
			this.refreshed = true;
		}

		if ($event.key === 'comparison.a') {
			this.comparison.a = $event.expectedValue;
		} else if ($event.key === 'comparison.b') {
			this.comparison.b = $event.expectedValue;
		} else if ($event.key === 'comparison.percMode') {
			this.comparison.percMode = $event.expectedValue;
		}

		if (this.comparison.a > 0 && this.comparison.b > 0) {
			this.onComparisonChanged.emit(this.comparison);
		}
	}


	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}