<p class="p-mt-0">
	In onderstaande lijst kunt aangeven voor welk gebied (of meerdere gebieden) u de KPI's wilt bekijken. De KPI's worden vervolgens standaard voor u gefilterd op deze gebieden.
</p>
<p class="p-mt-0">
	Mocht u tijdens het bekijken van een KPI toch ook geinteresseerd zijn in een ander gebied, dan kunt u dit terplekke aanpassen.
</p>
<p class="p-mt-0">
	Als u het vinkje 'Venster niet meer tonen' selecteert, wordt dit scherm niet meer getoond nadat u bent ingelogd. U kunt uw standaardselectie later weer aanpassen onder "Account" -> "Consessie voorkeuren".
</p>

<ng-container *ngIf="dummyPanel.andFilterFields.length === 1">
	<ng-container *ngFor="let filter of allFilters$ | async;">
		<ng-container *ngFor="let field of dummyPanel.andFilterFields">

			<ng-container *ngIf="filter.key === field.key && dummyPanel.visible">
				<h3 class="p-mt-0 p-mb-2">{{filtersMap.get(filter.key)?.title}} <span
					class="p-pl-1">{{getHeader(field)}}</span></h3>

				<base-edit-filter-multiple
					(onFilterFieldChanged)="filterChanged($event)"
					[panel]="dummyPanel"
					[filter]="filter">
				</base-edit-filter-multiple>
			</ng-container>

		</ng-container>
	</ng-container>
</ng-container>

<ng-container *ngIf="dummyPanel.andFilterFields.length > 1">
	<p-accordion [style]="{'padding-top': '.5rem'}">
		<ng-container *ngFor="let filter of allFilters$ | async;">
			<ng-container *ngFor="let field of dummyPanel.andFilterFields">

				<p-accordionTab [header]=""
								[selected]="dummyPanel.andFilterFields.length === 1"
								*ngIf="filter.key === field.key && dummyPanel.visible">

					<ng-template pTemplate="header">
						{{filtersMap.get(filter.key)?.title}} <span class="p-pl-1">{{getHeader(field)}}</span>
					</ng-template>

					<base-edit-filter-multiple
						(onFilterFieldChanged)="filterChanged($event)"
						[panel]="dummyPanel"
						[filter]="filter">
					</base-edit-filter-multiple>

				</p-accordionTab>

			</ng-container>
		</ng-container>
	</p-accordion>
</ng-container>



