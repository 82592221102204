import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'base-link',
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

	@Input() url: string;
	@Input() action: string;
	@Input() message: string;
	@Input() showLink: boolean = true;

	constructor() {
	}

	ngOnInit() {
	}

}
