import {Component, OnInit} from "@angular/core";
import _ from 'lodash';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'base-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent implements OnInit {

	path: string;
	buttonText: string = 'Kopieer';

	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
	}

	ngOnInit() {
		this.path = this.config.data.path;
	}

	copy(inputElement: HTMLInputElement) {

		/* Select the text field */
		inputElement.focus();
		inputElement.select();
		inputElement.setSelectionRange(0, 99999); /*For mobile devices*/

		/* Copy the text inside the text field */
		const ok = document.execCommand("copy");
		if(ok) {
			this.buttonText = 'Gekopieerd!';
			_.delay(() => {
				this.buttonText = 'Kopieer';
			}, 3000);
		}

	}

}
